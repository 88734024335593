
.loading-spinner {
    border-radius: 50%;
    border-top: 16px solid blue !important;
    border-bottom: 16px solid blue !important;
    border: 16px solid #f3f3f3;
    width: 120px;
    height: 120px;
    -webkit-animation: spinner 2s linear infinite;
    animation: spinner 2s linear infinite;
  }
  .loading {
    
    height: 50px;
    width: 50px;
    padding: 0;
    border: 4px solid #8e70c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: rotate 1s infinite linear;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -25px auto 0 auto;
}
.loader-box {
  /* position: fixed; */
  background: rgb(255 255 255 / 0.80);
  height: 100%;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
  @-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }