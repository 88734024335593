.doctor_reg_tabs {
    max-width: 512px;
    margin: 0 auto;
    margin-top: 20px;
}
.doctor_reg_screen .tab-content {
    max-width: 512px;
    margin: 0 auto;
    padding-top: 40px;
}
.doctor_reg_tabs li {
    width: 33.3%;
    font-size: 14px;
}
.doctor_reg_tabs li button{
    color: #262626;
    width: 100%;
    padding: 20px 0;
}
.doctor_reg_tabs li button:hover{
    color: #262626;
    border: none;
}
.doctor_reg_tabs li .badge{
    background-color: #fff !important;
    border: 1px solid rgba(38, 38, 38, 0.3);
    color: #262626;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    line-height: 12px;
    margin-right: 8px;
    font-weight: 400;
    padding: 4px 0;
}
.doctor_reg_tabs li .active .badge{
    background-color: #1DCE00 !important;
    border: 1px solid #1DCE00;
    color: #fff;
}
.doctor_reg_tabs li .active{
    border: none;
    border-bottom: 2px solid #3f2783 !important;
}
.doctor_org_info_screen .reg_logo {
    margin: 0 auto;
    display: block;
    margin-bottom: 35px;
}
.doctor_org_info_screen .reg_title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
.doctor_org_info_screen .reg_sub_title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 45px;
}
.doctor_org_info_screen .drop-zone {
    max-width: 100%;
    height: 120px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 2px dashed rgba(63, 39, 131, 0.2);
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
}
.doctor_org_info_screen .file-msg {
    font-weight: 400;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    text-align: center;
    display: block;
    margin: 0 auto;
    color: #000;
}
.doctor_org_info_screen .file-msg img{
	display: inline-block;
    margin-right: 5px;
}
.doctor_org_info_screen .file-msg strong{
	color: #3F2783;
	font-weight: 600;
}
.doctor_org_info_screen .file_drop_btm p{
    font-size: 12px;
}
.doctor_org_info_screen .drop-zone input {
    width: 100%;
    position: absolute;
    height: 100%;
    opacity: 0;
}
.doctor_org_info_screen .custom_check_box label {
    color: #000;
    font-weight: 400;
}

p.skip_the_setup.text-center {
    margin-top: 20px;
    color: #3f2783;
    font-weight: 700;
    cursor: pointer;
}
.form_panel{
    width: 650px;
    padding-top: 20px;
}
.form_panel_body{
   padding: 0px 40px 25px 40px;
}
.text_heading{
    font-weight: 700;
    color: #3f2783;
    font-size: 28px;
}
.text-bold{
    font-weight: 700;
    font-size: 20px;
}
.text-bold-com{
    font-weight: 700;
    font-size: 28px;
}
.text-bold-sign{
    font-weight: 400;
    font-size: 22px;
}


