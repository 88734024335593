.initial_title span, .recent_title span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -3px;
}
.initial_title span {
    background: #ff0000;
}
.recent_title span {
    background: #006dff;
}
.initial_score_row {
    color: #ff0000;
}
.recent_score_row {
    color: #006dff;
}
.initial_score_row span, .recent_score_row span {
    font-weight: 600;
}
.initial_title, .recent_title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}
.scorebox_title {
    margin: 0;
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 18px;
}
.chart_area {
    padding-top: 15px;
}
.left_sc_row {
    display: flex;
    align-items: center;
}
.pr_circles {
    display: flex;
    align-items: center;
}
.left_sc_row .score_title {
    min-width: 80px;
}
.left_sc_row .score_values {
    width: 60px;
}