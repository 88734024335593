
.App_logo {
    pointer-events: none;
    /* width: 100px; */
    /* margin-bottom: 50px; */
    margin-top: 20px;
}
.db_welcome_section h4 {
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0 10px 0;
  color: #000000;

}
.db_welcome_section p {
  font-size: 18px;
}
.card_individual_sec{
  /* padding-top: 60px; */
  padding-top: 0px;

}
.card_individual_sec .whitebox{
  max-height: 270px;
  padding: 40px 20px;
}
.card_individual_sec .whitebox h4 {
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
  color: #000000;

}
.card_individual_sec .whitebox p {
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 17.92px;
}
.card_individual_sec button.btn_outline {
  font-size: 14px;
  min-width: 126px;
  height: 38px;
  line-height: 35px;
}
.add_task_hr {
  border: 1px solid rgba(63, 39, 131, 0.2);
  border-radius: 10px;
  min-height: 60px;
  margin-bottom: 20px;
}
.add_task_hr span {
  display: block;
  padding: 16px 14px;
  font-size: 14px;
}
.add_task_hr .add_tsk_plus {
  width: 24px;
  margin: 0px 10px;
  margin-left: 5px;
}
.savetask img {
  cursor: pointer;
}



@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  