p {
  word-break: break-word;
}
  .grabbable {
    flex: 0 0 auto !important;
    width: 50% !important;
    margin-bottom: 20px !important;
  }
  .score_widget_sec {
    height: 100%;
}