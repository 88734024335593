.visits_initial, .visits_fallowup {
    display: flex;
    margin-bottom: 15px;
    position: relative;
}
.visits_initial .visits_inner, .visits_fallowup .visits_inner{
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
    padding: 8px 20px;
    width: 97%;
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    align-items: center;
}

.visits_inner:hover{
    border: 2px solid #3f2783;
    cursor: pointer;
}


.visits_left_content {
    width: calc(100% - 102px);
}
.visits_right_btn {
    width: 100px;
    text-align: right;
}
.visits_assdet {
    margin-bottom: 6px;
    color: #3f2783;
    font-weight: 600;
}
.visits_dctrdet {
    margin-bottom: 0;
    color: #3f2783;
    font-style: italic;
    /* font-weight: 600; */
}

.visits_fallow_connect {
    width: 60px;
    position: relative;
}
.visits_fallow_connect:after {
    content: "";
    height: 124%;
    width: 2px;
    background: #3f2783;
    position: absolute;
    right: 28px;
    top: -16px;
}
.visits_fallowup:last-child .visits_fallow_connect:before {
    content: "";
    height: 30px;
    width: 30px;
    background: #fff;
    position: absolute;
    right: 15px;
    bottom: 0px;
    z-index: 01;
}
.visits_triangle  {
    width: 0;
    height: 0;
    border-width: 7px 0 7px 14px;
    border-color: transparent transparent transparent #3f2783;
    border-style: solid;
    display: block;
    position: absolute;
    right: 0;
    top: 28px;
}
.visits_trianglearb  {
    width: 0;
    height: 0;
    border-width: 7px 14px 7px 0;
    border-color: transparent #3f2783 transparent transparent;
    border-style: solid;
    display: block;
    position: absolute;
    left: 0;
    top: 28px;
}
.visits_triangle:before {
    content: "";
    height: 2px;
    width: 16px;
    background: #3f2783;
    position: absolute;
    left: -28px;
    top: -1px;
}
.visits_trianglearb:before {
    content: "";
    height: 2px;
    width: 16px;
    background: #3f2783;
    position: absolute;
    left: 13px;
    top: -1px;
}

