.box_title {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}
.initial_title, .recent_title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
.box_header .recent_title {
    margin-left: 24px;
}
.initial_title span, .recent_title span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 15px;
}
.initial_title span {
    background: #ff0000;
}
.recent_title span {
    background: #006dff;
}
.range_table {
    overflow: auto;
}

.range_table table tbody tr td {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    padding: 11px 20px 12px;
    font-size: 14px;
    font-weight: 600;
}
.initial_value {
    color: #ff0000;
}
.range_table table tbody tr:last-child td {
    border-bottom: none;
}
.range_table table tbody tr td:first-child {
    padding-left: 0;
}
.range_table table tbody tr td:last-child {
    padding-right: 0;
}
.range_table table{
    width: 100%;
 }
@media only screen and (min-width: 993px) and (max-width: 1700px) {
    .range_table table{
        width: 100%;
     }
} 