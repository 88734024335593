.scoreWidgetSec .whitebox {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
 
}
.scorebox_title {
    vertical-align: top;
    width: calc(100% - 203px);
    text-align: left;
}
.scorebox_title h3{
  font-size: 24px;
  font-weight: 600;
}
.scorebox_title .is_target_text {
    color: red;
    font-weight: 400;
}
.scorebox_title h4 strong {
    font-size: 64px;
    display: block;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
}
.scorebox_title label {
    font-size: 14px;
    font-weight: normal;
    color: #000;
}
.skill_box {
    width: 202px;
    padding-top: 50px;
}
.skillbox_btm_title {
    font-size: 12px;
}