.body-content.inner-body-content {
    padding: 20px 70px;
}
.inner-content-box h2 {
    font-size: 64px;
    line-height: 85px;
    margin-bottom: 30px;
}
.input-box.input-box-text {
    margin-right: 0 !important;
}
.input-box.input-box-fwidth {
    width: 100%;
}
.formfill {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
}
.fieldtext-name {
    display: block;
    margin-bottom: 15px;
}

.form-fieldtext {
    vertical-align: bottom;
    margin-left: 5px;
}
span.left_label_txt {
    display: inline-block;
    width: 251px;
    font-weight: 500;
}
.fieldtext-name .rt_input_txt {
    display: inline-block;
    min-width: 140px;
    border-bottom: 2px solid #000;
}
span.right_dt {
    border-bottom: 2px solid #000;
}
.dc_name{
    border-bottom: 2px solid #000;

}

.input-box-text p {
    font-size: 24px;
    line-height: 30px;
    /* margin-bottom: 30px; */
}
#consentform strong.d__block {
    display: block;
    padding-bottom: 10px;
    font-weight: 600;
}
.text_dec_none {
    text-decoration: inherit !important;
}

.ml-15 {
    margin-left: 15px;
}
.input-row .input-box:nth-child(odd) {
    margin-right: 15px;
}
.input-box.input-tab-boxin {
    /* border: 2px solid rgba(63, 39, 131, 0.2); */
    border: 2px solid #3F2783;
    border-radius: 20px;
    padding: 25px;
    margin-right: 0 !important;
}

.input-box label {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}
.fill-here {
    width: 100%;
    height: 46px;
    /* border: 2px solid rgba(63, 39, 131, 0.2); */
    border: 2px solid #3F2783;
    border-radius: 20px;
    font-size: 14px;
    color: rgba(63, 39, 131, 0.7);
    padding: 0 15px;
    background: transparent;
    box-shadow: none;
    margin-bottom: 20px;
}

.consent__btm_date .input-box {
    display: flex;
    align-items: baseline;
}

.consent__btm_date .input-box .date-inputbox {
    margin-left: 10px;
    border-bottom: 1px solid #000;
    width: auto;
}
.patient-information-form .date-inputbox {
    width: 225px;
}
.date-inputbox {
    width: 265px;
    position: relative;
}
.mt-20{
    margin-top: 20px;
}
.SignaturePad{
    position: relative;
    border: 2px solid #3F2783;
    border-radius: 20px;
    /* padding: 25px; */
    margin-right: 0 !important;
}
.clearlink {
    position: absolute;
    right: 20px;
    top: 15px;
    color: #3f2783;
    line-height: 14px;
    z-index: 1;
}
.clearlink img {
    margin-right: 8px;
}
.consent_txt h2 {
    font-size: 40px !important;
    color: #ea702b;
    font-weight: 600;
    line-height: 51px;
}