.left_tab_menu{
    border-bottom: none;
    border-right: 1px solid rgba(63, 39, 131, 0.1);
    padding-top: 20px;
    min-height: 605px;
    height: 100%;
}
.nav_item .nav_link{
    color: #828282;
    background-color: #fff;
    padding: 10px 6px 10px 40px;
}
.tab_content {
    padding-top: 20px;
    max-width: none !important;
}
.content_main_title{
    color: #3f2783;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}
hr {
    margin-top: 5px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    width: 100%;
    margin-bottom: 25px;
}