table.custom-tbl {
	border-collapse: collapse;
	width: 100%;
}
table.custom-tbl thead tr th {
	font-weight: 600;
	font-size: 14px;
	color: #3F2783;
	padding: 10px 0;
	width: 25%;
}
table.custom-tbl>thead>tr>th {
    border-bottom: 1px !important;
}
table.custom-tbl tr th {
    border-color: rgba(63, 39, 131, 0.1) !important;
}
table.custom-tbl td {
    line-height: 28px;
    font-size: 14px;
    color: #3f2783;
}
table.custom-tbl tbody tr td {
    font-size: 14px;
    color: #3f2783;
    padding: 14px 2px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    text-transform: capitalize;
}
table.custom-tbl thead tr th.col-img{
	width: 80px;
	padding: 14px 5px;
}
table.custom-tbl thead tr th.col-img, table.custom-tbl tbody tr td.col-img{
	padding: 14px 8px;
	padding-right: 20px;
}
table.custom-tbl thead tr th {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    padding: 10px 10px;
    width: 25%;
    background-color: #3f2783;
}
table.custom-tbl tbody tr td {
    font-size: 14px;
    color: #3f2783;
    padding: 14px 10px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}
.chec_lb_before {
    color: #3f2783;
    font-size: 16px;
    font-weight: 600;
    /* margin-bottom: 15px; */
    padding: 12px;
    max-width: 700px;
    border-radius: 16px;
}
.chec_lb_before h6 {
    font-weight: 600;
    margin-bottom: 15px;
}

.chec_lb_before span {
    font-weight: 400;
}